































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Page401'
})
export default class extends Vue {
  private errGif = require('@/assets/401-images/401.gif');
  // private errGif = _url + '?' + new Date();
  private ewizardClap = 'https://wpimg.wallstcn.com/007ef517-bafd-4066-aae4-6883632d9646';
  private dialogVisible = false;

  private back() {
    if (this.$route.query.noGoBack) {
      this.$router.push({ path: '/' });
    } else {
      this.$router.go(-1);
    }
  }
}
